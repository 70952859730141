import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="It Blomhôf" />
    <h1>Welkom bij It Blomhôf</h1>
    <p>It Blomhôf is een permacultuur bosrandtuin ("voedselbos") gestart in 2019 in het Noord-Hollandse <a href="https://nl.wikipedia.org/wiki/Wognum">Wognum</a>. Op deze site is de ontwikkeling van de tuin te volgen, en proberen we opgedane kennis te delen.</p>
    <div style={{ maxWidth: `1024px`, marginBottom: `1.45rem` }}>
      <h2>Het ontwerp</h2>
      <h6 class="date">10 augustus 2019</h6>
      <Image />
    </div>
  </Layout>
)

export default IndexPage
